/* eslint-disable no-promise-executor-return */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
import React from 'react';

import './App.css';
import checkVisible from './Resources';
import setinhaLeft from './Media/setinhaLeft.svg';
import setinhaRight from './Media/setinhaRight.svg';
import instagramBlue from './Media/instagramBlue.svg';
import linkedinBlue from './Media/linkedinBlue.svg';
import facebookBlue from './Media/facebookBlue.svg';
import instagramWhite from './Media/instagramWhite.svg';
import linkedinWhite from './Media/linkedinWhite.svg';
import facebookWhite from './Media/facebookWhite.svg';
import pneus from './Media/pneus.png';
import pecas from './Media/pecas.png';
import baterias from './Media/baterias.png';
import lubrificantes from './Media/lubrificantes.png';
import pmzLogo from './Media/pmzLogo.svg';
import pmzMap from './Media/pmzMap.svg';
import pneusGif from './Media/pneus.gif';
import pecasGif from './Media/pecas.gif';
import bateriasGif from './Media/baterias.gif';
import lubrificantesGif from './Media/lubrificantes.gif';
import nossaHistoria from './Media/nossaHistoria.svg';
import nossaHistoriaMobile from './Media/nossaHistoriaMobile.svg';
import missao from './Media/missao.svg';
import visao from './Media/visao.svg';
import valores from './Media/valores.svg';
import link from './Media/link.svg';
import agoraNoNordeste from './Media/agoraNoNordeste.svg';
import Endereco from './Media/Endereco.svg';
import Telefone from './Media/Telefone.svg';
import Horarios from './Media/Horario.svg';
import externalLink from './Media/linkWhite.svg';

let nossaHistoriaImage = nossaHistoria;

function App() {
  const [currentBanner, setCurrentBanner] = React.useState();
  const estadosCidadesELojas = { 
    Amazonas: { 
      Manaus: [
        { Endereço: 'AV. COSME FERREIRA, 3970 - COROADO - CEP: 69082-230', telefone: '(92) 3647-2650', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. MARGARITA, 01 QD 186 LOTE 01  - NOVA CIDADE - CEP: 69097-305', telefone: undefined, funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. COMPENSA, 296 B – VILA DA PRATA - CEP: 69030-575', telefone: '(92) 3672-3100', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. RECIFE, 514 - FLORES CEP: 69058-775', telefone: '(92) 3643-9200', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. SILVES, 2159 - CRESPO CEP: 69073-175', telefone: '(92) 3614-0800', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: '08:00 - 12:00' } },
        { Endereço: 'AV. ÁLVARO MAIA, 373 - SÃO GERALDO CEP: 69053-350', telefone: '(92) 3133-2250', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. ANDRÉ ARAÚJO, 123 - ALEIXO,  CEP 69067-375', telefone: '(92) 3612-3700', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. MAX TEIXEIRA, 3123 - CIDADE NOVA CEP: 69090-002', telefone: '(92) 3581-9600', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. LOURENCO DA SILVA BRAGA, 692, CENTRO - CEP 69005-015', telefone: '(92) 3198-5200', funcionamento: { segundaASexta: '07:30 - 17:30', sabado: '07:30 - 12:30', domingo: undefined } },
        { Endereço: 'AV. VISCONDE DE PORTO ALEGRE, 1621 - Praça 14 de Janeiro CEP: 69020-130', telefone: '(92) 3198-5250', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. GOVERNADOR JOSÉ LINDOSO, 4565 - FLORES - CEP 69058-175', telefone: '(92) 3649-5300', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RUA PROFESSOR ABÍLIO ALENCAR, 1299 - ALVORADA - CEP: 69042-010', telefone: '(92) 3659-4400', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. AUTAZ MIRIM, 8589 – NOVO ALEIXO - CEP: 69099-045', telefone: '(92) 3247-1150', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: '08:00 - 12:00' } },
        { Endereço: 'AV. CAMAPUÃ, 55 - CONJ RESID. CIDADE NOVA SETOR 2ª ET. - CIDADE NOVA CEP: 69097-720', telefone: '(92) 3649-2500', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. TORQUATO TAPAJÓS, 319 – PAZ – CEP: 69048-010', telefone: '(92) 3667-8100', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. ARQUITETO JOSÉ HENRIQUES B.RODRIGUES, 1818 – COLÔNIA TERRA NOVA – CEP: 69015-615', telefone: '(92) 3667-8000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. CASTELO BRANCO, 1371 CASA A – CACHOEIRINHA – CEP: 69065-011', telefone: '(92) 3131-8050', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RUA SANTA LÚCIA, 360 – JORGE TEIXEIRA – CEP: 69099-235', telefone: '(92) 3247-2000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. 7 DE MAIO, 431 - SANTA ETELVINA  - CEP 69059-140', telefone: '(92) 3198-5000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. MAX TEIXEIRA, 1.190, FLORES CEP: 69058-415', telefone: '(92) 3617-9900', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: '08:00 - 12:00' } },
        { Endereço: 'AV. TORQUATO TAPAJÓS, 8594 – COLONIA TERRA NOVA – CEP: 69093-415', telefone: '(92) 3652-6000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. DESEMBARGADOR JOÃO MACHADO, 1102 - ALVORADA II CEP: 69044-000', telefone: '(92) 3659-4450', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. TEFÉ, 2881 - JAPIIM CEP: 69078-000', telefone: '(92) 3652-6000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Itacoatiara: [
        { Endereço: 'AV. 07 DE SETEMBRO, 983 - CENTRO - CEP: 69100-000', telefone: '(92) 3521-9550', funcionamento: { segundaASexta: '07:30 - 17:30', sabado: '07:30 - 12:30', domingo: undefined } },
      ] },


    Pará: {
      Belém: [
        { Endereço: 'AV. JOSÉ BONIFÁCIO, 2628 - GUAMÁ. CEP: 66065-112', telefone: '(91) 3213-8600', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AVENIDA PEDRO ÁLVARES CABRAL, 3661 - SACRAMENTA CEP: 66120-620', telefone: '(91) 3210-7000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RODOVIA MÁRIO COVAS, 159 - UNA CEP: 66652-000', telefone: '(91) 3218-5000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'ESTRADA DO TAPANÃ, S/N, BAIRRO TAPANÃ CEP: 66858-010', telefone: '(91) 3217-8050', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RODOVIA AUGUSTO MONTENEGRO, 1910 - PARQUE GUAJARÁ (ICOARACI) CEP: 66821-000', telefone: '(91) 3216-8000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. SENADOR LEMOS, 2483 - SACRAMENTA CEP: 66120-000', telefone: '(91) 3239-4000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Marituba: [
        { Endereço: 'RODOVIA BR 316, S/N - SÃO JOÃO CEP: 67200-000', telefone: '(91) 3215-5600', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Ananindeua: [
        { Endereço: 'AV. INDEPENDÊNCIA, 2013 B, ICUÍ GUAJARÁ, CEP: 67125-406', telefone: '(91) 3250-2000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RODOVIA BR 316, KM 06, COQUEIRO CEP: 67015-220', telefone: '(91) 3289-9000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Castanhal: [
        { Endereço: 'AV. PRESIDENTE GETULIO VARGAS, 1588, IANETAMA CEP: 68745-000', telefone: '(91) 3412-6000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Santarém: [
        { Endereço: 'AV. CUIABÁ, 2538 LOTE 02 - ESPERANÇA CEP: 68040-400', telefone: '(93) 3512-0150', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. MAGALHÃES BARATA, 1432 - CARANAZAL CEP: 68030-700', telefone: '(93) 3512-0200', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. CURUA UNA, 3341 - SÃO JOSÉ OPERÁRIO CEP: 68020-650', telefone: '(93) 3512-5450', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Novo_Progresso: [
        { Endereço: 'AV. DOS PRAZERES, 1593 - VISTA ALEGRE CEP: 68193-000', telefone: '(93) 3528-1171', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Ruropolis: [
        { Endereço: 'ROD. TRANSAMAZÔNICA LOTE 05 – 000 – ÁREA INDUSTRIAL CEP: 68165-000', telefone: '(93) 3543-1122', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Altamira: [
        { Endereço: 'AV. PERIMETRAL, 3926 - SUDAN II CEP: 68374-274', telefone: '(93) 3502-7600', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Uruará: [
        { Endereço: 'RUA 01, 204 - INDUSTRIAL CEP: 68140-000', telefone: '(93) 3532 3100', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],

      Parauapebas: [
        { Endereço: 'ROD. PA 275 KM 63,4 – S/N - ZONA RURAL CEP: 68515-000', telefone: '(94) 3352-6500', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],
    
    },

    Amapá: {
      Macapá: [
        { Endereço: 'RUA ELIEZER LEVY, 1976 - CENTRAL - CEP: 68900-083', telefone: '(96) 3198-2650', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. JOSÉ TUPINAMBA DE ALMEIDA, 1570 – JESUS DE NAZARÉ - CEP: 68908-126', telefone: '(96) 2101-4500', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: '08:00 - 12:00' } },
        { Endereço: 'RUA AÇAI, S/N - LOTEAMENTO AÇAI - CEP: 68908-833', telefone: '(96) 3216-8000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RUA HILDEMAR MAIA, 2374 - BURITIZAL - CEP: 68902-870', telefone: '(96) 3225-0550', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'ROD. DE DUCA SERRA - 929 A - CABRALZINHO - CEP: 68906-801', telefone: '(96) 3261-1619', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'ROD. JUSCELINO KUBITSCHEK - 620 - MARCO ZERO - CEP: 68903-197', telefone: '(96) 3203-0900', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],
    },

    Roraima: {
      Boa_Vista: [
        { Endereço: 'AV. DAS GUIANAS, 1714 QUADRA 73 LOTES 3 E 4 - SÃO VICENTE, CEP: 69308-160', telefone: '(95) 2121-4700', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: '08:00 - 12:00' } },
        { Endereço: 'AV. SÃO SEBASTIAO, 2047 - SANTA TEREZA - CEP: 69314-152', telefone: '(95) 3621-6950', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. VENEZUELA, 359 - PRICUMA - CEP: 69309-690', telefone: '(95) 3621-6900', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RUA ESTRELA DALVA, 185 – RAIAR DO SOL CEP: 69316-002', telefone: '(95) 3194-0700', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'RUA DAS PAPOULAS, 32, PRICUMA - CEP: 69309-610', telefone: '(95) 3194-4000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. CENTENÁRIO, 873 – CENTENÁRIO – CEP: 69312-603', telefone: '(95) 3198-4050', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],
    },

    Mato_Grosso: {
      Cuiabá: [
        { Endereço: 'AV. CARMINDO DE CAMPOS BAIRRO JARDIM PAULISTA, N 1920, CEP: 78065-310', telefone: '(65) 3313-4100', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
        { Endereço: 'AV. ARCHIMEDES PEREIRA LIMA, BAIRRO: BOA ESPERANCA, N 2610, CEP: 78068-685', telefone: '(65) 3619-4900', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
        { Endereço: 'RUA ROMA (LOT RODOVIARIA PARQUE), BAIRRO DESPRAIADO, N 168 A, CEP: 78048-140', telefone: '(65) 3615-1850', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
        { Endereço: 'AV. FERNANDO CORREA DA COSTA, BAIRRO JARDIM PRESIDENTE, N 6234, CEP: 78090-000', telefone: '(65) 3614-4000', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
      ],

      Várzea_Grande: [
        { Endereço: 'AV. DA FEB (LOT PTE NOVA), BAIRRO PONTE NOVA, N 1150, CEP: 78115-005', telefone: '(65) 3688-2500', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
        { Endereço: 'AV. JULIO DOMINGOS DE CAMPOS (LOT JD GLORIA), BAIRRO GLORIA, N 3200, CEP: 78140-340', telefone: '(65) 3688-2500', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
        { Endereço: 'AV. FILINTO MULLER (LT JD MARAJOARA I) S/N, BAIRRO MARAJOARA, CEP: 78138-746', telefone: '(65) 3688-2500', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
      ],

      Sorriso: [
        { Endereço: 'AV. PERIMETRAL SUDESTE, BAIRRO: BENJAMIM RAISER, N 11991, CEP: 78896-114', telefone: '(66) 3907-8800', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
      ],

      Sinop: [
        { Endereço: 'RUA JOAO PEDRO MOREIRA DE CARVALHO, BAIRRO SETOR INDUSTRIAL, CEP: 78557-135', telefone: '(66) 3371-3100', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
      ],

      Campo_Verde: [
        { Endereço: 'AV. SEN. ATILIO FONTANA, N 2502, BAIRRO JARDIM CAMPO VERDE, CEP: 78840-000', telefone: '(66) 3419-6650', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
      ],

      Nova_Mutum: [
        { Endereço: 'AV. PERIMETRAL DAS SAMAMBAIAS, N 1055, BAIRRO JARDIM, CEP: 78450-000', telefone: '(66) 3517-1750', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 14:00', domingo: undefined } },
      ],
    },

    Maranhão: {
      São_Luís: [
        { Endereço: 'AV. PRES MEDICI/AFRICANOS, N 30, COROADINHO, CEP: 65040-515', telefone: '(98) 98474-9574', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. GUAJAJARAS, N 1117 BAIRRO TIRIRICAL CEP: 65055-285', telefone: '(98) 3334-9530', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. GUAXENDUBA, N 1985, AREINHA, CEP: 65015-560', telefone: '(98) 98422-4216', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. JERONIMO DE ALBUQUERQUE, N 642 - CRUZEIRO DO ANIL, CEP: 65060-715', telefone: '(98) 98408-1864', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
        { Endereço: 'AV. GUAJAJARAS 03, 02, QUADRA 60, BAIRRO COHAPAM, CEP: 65056-045', telefone: '(98) 97006-2464', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: '08:00 - 12:00' } },
        { Endereço: 'ROD. BR 135 (AVN.ENG.EMILIANO MACIEIRA KM:4) TIBIRI, CEP: 65095-601 ', telefone: undefined, funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } },
      ],
    },
  };

  const allStates = Object.keys(estadosCidadesELojas);

  const [currentState, setCurrentState] = React.useState(allStates[0]);
  const [currentCity, setCurrentCity] = React.useState(Object.keys(estadosCidadesELojas[currentState])[0]);
  const [currentStores, setCurrentStores] = React.useState(estadosCidadesELojas.Amazonas.Manaus);
  const [currentStore, setCurrentStore] = React.useState({ Endereço: 'AV. COSME FERREIRA, 3970 - COROADO - CEP: 69082-230', telefone: '(92) 3647-2650', funcionamento: { segundaASexta: '07:30 - 18:00', sabado: '07:30 - 15:00', domingo: undefined } });
  const [cityAndStatePair, setCityAndStatePair] = React.useState('Manaus, Amazonas');


  function returnCurrentStateCities() {
    return Object.keys(estadosCidadesELojas[currentState]);
  }

  const firstBannerDiv = (
    <div className="bannerDiv firstBannerDiv">
      <div className="bannerDivText">
        <h1 className="blueText">
          A maior rede de peças e pneus
        </h1>
        <p className="blueText mobileSmaller">
          da região Norte e Centro-Oeste
          <span className="agoraNoNordeste"><span className="blueText">,</span> agora no Nordeste <img src={agoraNoNordeste} alt="" /></span>
        </p>
      </div>
      <div className="arrowsContainer">
        <img onClick={() => setCurrentBanner(firstBannerDiv)} src={setinhaLeft} alt="" />
        <img onClick={() => setCurrentBanner(secondBannerDiv)} src={setinhaRight} alt="" />
      </div>
      <div className="socialsContainer blueSocials">
        <img onClick={() => window.open('https://www.instagram.com/grupopmz/')} src={instagramBlue} alt="instagram" />
        <img onClick={() => window.open('https://www.linkedin.com/company/pmzpe%C3%A7asepneus/')} src={linkedinBlue} alt="Linkedin" />
        <img onClick={() => window.open('https://www.facebook.com/GrupoPMZ/')} src={facebookBlue} alt="Facebook" />
      </div>

      <div className="imageSelectorsContainer">
        <div onClick={() => setCurrentBanner(firstBannerDiv)} className="selected" />
        <div onClick={() => setCurrentBanner(secondBannerDiv)} />
      </div>
    </div>
  );

  const secondBannerDiv = (
    <div className="bannerDiv secondBannerDiv">
      <div className="bannerDivText">
        <h1 className="whiteText">
          A solução completa para seu automóvel
        </h1>
        <p className="whiteText">
          Confira nossos produtos
        </p>
      </div>
      <div className="arrowsContainer">
        <img onClick={() => setCurrentBanner(firstBannerDiv)} src={setinhaLeft} alt="" />
        <img onClick={() => setCurrentBanner(secondBannerDiv)} src={setinhaRight} alt="" />
      </div>
      <div className="socialsContainer whiteSocials">
        <img onClick={() => window.open('https://www.instagram.com/grupopmz/')} src={instagramWhite} alt="instagram" />
        <img onClick={() => window.open('https://www.linkedin.com/company/pmzpe%C3%A7asepneus/')} src={linkedinWhite} alt="Linkedin" />
        <img onClick={() => window.open('https://www.facebook.com/GrupoPMZ/')} src={facebookWhite} alt="Facebook" />
      </div>

      <div className="imageSelectorsContainer">
        <div onClick={() => setCurrentBanner(firstBannerDiv)} />
        <div onClick={() => setCurrentBanner(secondBannerDiv)} className="selected" />
      </div>

      <div className="nossosProdutosDiv">
        <div>
          <h2 className="whiteText">Pneus</h2>
          <div className="produtosDecoration" />
          <img src={pneus} alt="" />
        </div>

        <div>
          <h2 className="whiteText">Peças</h2>
          <div className="produtosDecoration" />
          <img src={pecas} alt="" />
        </div>

        <div>
          <h2 className="whiteText">Baterias</h2>
          <div className="produtosDecoration" />
          <img src={baterias} alt="" />
        </div>

        <div>
          <h2 className="whiteText">Lubrificantes</h2>
          <div className="produtosDecoration" />
          <img src={lubrificantes} alt="" />
        </div>
      </div>
    </div>
  );

  React.useEffect(() => {
    document.getElementById('pmzHome').style.borderBottomColor = '#FFF';
    new window.RDStationForms('contato-site-tampao-f79aaf1c02be68d5ceb7', 'UA-267824461-1').createForm();
    if (screen.width <= 1024) {
      nossaHistoriaImage = nossaHistoriaMobile;
    }
    document.addEventListener('scroll', () => {
      const isHomeOnScreen = checkVisible(document.querySelector('.pmzHome'));
      if (isHomeOnScreen === true) {
        document.getElementById('pmzHome').style.borderBottomColor = '#FFF';
      } else {
        document.getElementById('pmzHome').style.borderBottomColor = 'transparent';
      }

      const isSobreOnScreen = checkVisible(document.querySelector('.pmzSobre'));
      if (isSobreOnScreen === true) {
        document.getElementById('pmzSobre').style.borderBottomColor = '#FFF';
      } else {
        document.getElementById('pmzSobre').style.borderBottomColor = 'transparent';
      }

      const isContatoOnScreen = checkVisible(document.querySelector('.pmzContato'));
      if (isContatoOnScreen === true) {
        document.getElementById('pmzContato').style.borderBottomColor = '#FFF';
        document.getElementById('pmzConosco').style.borderBottomColor = '#FFF';
      } else {
        document.getElementById('pmzContato').style.borderBottomColor = 'transparent';
        document.getElementById('pmzConosco').style.borderBottomColor = 'transparent';
      }
    });

    const sumNumbersInterval = setInterval(() => {
      const numbersToSum = Array.from((document.getElementsByClassName('numberToSum')));
      numbersToSum.forEach((element) => {
        if (checkVisible(element) === true) {
          let maximumNumber = 0;
          let sumUsingThis = 1;
          switch (element.id) {
            case 'funcionarios':
              maximumNumber = 2100;
              sumUsingThis = 100;
              break;
            case 'lojas': 
              maximumNumber = 77;
              sumUsingThis = 5;
              break;
            case 'distribuicao': 
              maximumNumber = 8;
              break;
            case 'estados': 
              maximumNumber = 6;
              break;
            case 'itens': 
              maximumNumber = 160;
              sumUsingThis = 10;
              break;
            case 'anos': 
              maximumNumber = 36;
              break;
            default:
              break;
          }
          if (Number(element.innerHTML) !== maximumNumber) {
            if ((Number(element.innerHTML) + sumUsingThis) >= maximumNumber) {
              element.innerHTML = maximumNumber;
              return;
            }
            element.innerHTML = Number(element.innerHTML) + sumUsingThis;
          } 
        }
      });
    }, 150);
    setCurrentBanner(firstBannerDiv);

    return (() => {
      clearInterval(sumNumbersInterval);
    });
  }, []);

  function openValoresText() {
    const valoresText = document.getElementById('valoresText');
    if (valoresText.style.height === 'auto') {
      valoresText.style.height = '';
    } else {
      valoresText.style.height = 'auto';
    }
  }

  function handleClick() {
    const inputValues = {};
    const input = Array.from(document.getElementsByClassName('inputValue'));
    input.forEach((value) => {
      inputValues[value.name] = value.value;
      if (value.value === '' || value.value === ' ') {
        inputValues.deuRuim = true;
      }
    });
    if (inputValues.deuRuim === true) {
      return window.alert('Preencha todos os campos para enviar o formulario');
    }
    console.log(inputValues);

    const emailJs = window.emailjs;
    emailJs.init('t3FV9rCI6hHS_Lr37');

    emailJs.send('service_8ck6icc', 'template_ntg8x4n', inputValues);
    return window.alert('Mensagem de contato enviada com sucesso!');
  }

  function changeCurrentState(trigger) {
    const originalStateName = trigger.value.replace(' ', '_');
    const stateFirstCity = Object.keys(estadosCidadesELojas[originalStateName])[0];
    setCurrentStores(estadosCidadesELojas[originalStateName][stateFirstCity]);
    setCurrentStore(estadosCidadesELojas[originalStateName][stateFirstCity][0]);
    setCurrentCity(returnCurrentStateCities()[0]);
    setCityAndStatePair(`${stateFirstCity.replace('_', ' ')}, ${trigger.value}`);
    setCurrentState(originalStateName);
  }

  function changeCurrentCity(trigger) {
    const originalCityName = trigger.value.replace(' ', '_');
    setCurrentStores(estadosCidadesELojas[currentState][originalCityName]);
    setCurrentStore(estadosCidadesELojas[currentState][originalCityName][0]);
    setCityAndStatePair(`${trigger.value}, ${currentState.replace('_', ' ')}`);
    setCurrentCity(originalCityName);
  }

  function mapStores() {
    return currentStores.map((store) => {
      return (
        <div onClick={() => setCurrentStore(store)} style={store.Endereço === currentStore.Endereço ? { backgroundColor: '#254E70', color: '#FFF' } : {}}>
          {store.Endereço}
        </div>
      );
    });
  }

  return (
    <div>
      <header className="pmzHeader">
        <img alt="PMZ Peças e Pneus" src={pmzLogo} />

        {/* <a target="_blank" className="escolaMecanicos mecanicosMobile" href="https://lp.grupopmz.com/pmz-escola-para-mecanicos">
          Escola para Mecânicos
          <img src={link} alt="" />
        </a> */}

        <div className="headerLinks">
          <a id="pmzHome" href="#home">
            Home
          </a>
          <a id="pmzSobre" href="#sobreNos">
            Sobre Nós
          </a>
          <a id="pmzConosco" target="_blank" href="https://grupopmz.solides.jobs/">
            Trabalhe Conosco
          </a>
          {/* <a target="_blank" className="escolaMecanicos mecanicosDesktop" href="https://lp.grupopmz.com/pmz-escola-para-mecanicos">
            Escola para Mecânicos
            <img src={link} alt="" />
          </a> */}
          <a id="pmzContato" href="#contato">
            Contato
          </a>
        </div>
      </header>
      <div className="pmzHome" id="home">
        <div className="pmzPassableBanner">
          {currentBanner}
        </div>

        <div className="pmzFirstContent">
          <p>
            <span className="headingText" style={{ color: '#0B5189' }}>Você sabia?</span>
            <br />
            <br />
            <span className="biggerText blueText">
              Estamos presentes com 77 lojas e 8 Centros de Distribuição nas regiões Norte, Centro-Oeste e Nordeste.
            </span>
            <br />
            <br />
            <span className="standardText hideOnMobile">
              Aqui você encontra tudo para as linhas leve e pesada no atacado e varejo.
              <br />
              <br />
              Então quando seu veículo precisar: <span style={{ color: '#0B5189' }}>não fique sem, na PMZ tem!</span> 
            </span>
          </p>
          <img src={pmzMap} alt="map" />
          <p className="standardText showOnMobile">
            Aqui você encontra tudo para as linhas leve e pesada no atacado e varejo.
            <br />
            <br />
            Então quando seu veículo precisar: <span style={{ color: '#0B5189' }}>não fique sem, na PMZ tem!</span> 
          </p>
        </div>
      </div>

      <div className="pmzSobre" id="sobreNos">
        <div className="pmzGifsContainer">
          <div className="gifContainer">
            <img src={pneusGif} alt="" />
            <div className="gifContainerText">
              <h2 className="whiteText headingText">
                PNEUS
              </h2>

              <p style={{ fontWeight: 700 }} className="whiteText standardText">
                Temos uma gama completa de medidas de pneus para carros, caminhonetes, motos, caminhões, ônibus e veículos fora de estrada.
              </p>
            </div>
          </div>

          <div className="gifContainer">
            <img src={pecasGif} alt="" />
            <div className="gifContainerText">
              <h2 className="whiteText headingText">
                PEÇAS
              </h2>

              <p style={{ fontWeight: 700 }} className="whiteText standardText">
                São mais de 160 mil itens em nosso portfólio. 
              </p>
            </div>
          </div>

          <div className="gifContainer">
            <img src={lubrificantesGif} alt="" />
            <div className="gifContainerText">
              <h2 className="whiteText headingText">
                LUBRIFICANTES
              </h2>

              <p style={{ fontWeight: 700 }} className="whiteText standardText">
                Os lubrificantes são importantes itens para manutenção, proteção, redução de atrito, controle de temperatura, redução de desgaste, evita corrosão, oxidação, etc.
              </p>
            </div>
          </div>

          <div className="gifContainer">
            <img src={bateriasGif} alt="" />
            <div className="gifContainerText">
              <h2 className="whiteText headingText">
                BATERIAS
              </h2>

              <p style={{ fontWeight: 700 }} className="whiteText standardText">
                A PMZ conta com os maiores e melhores fornecedores de baterias do mercado. Possuímos inclusive baterias de 2 anos de garantia! Confira disponibilidade!            
              </p>
            </div>
          </div>
        </div>

        <div className="pmzHistoriaDiv">
          <h2 className="headingText whiteText">
            Nossa História
          </h2>
          <img src={nossaHistoriaImage} alt="Timeline da nossa história" />
        </div>

        <div className="pmzValoresDiv">
          <div className="valorContainer">
            <img src={missao} alt="" />
            <h2 className="headingText" style={{ color: '#0B5189' }}>Missão PMZ</h2>
            <p style={{ color: '#001926', fontSize: '16px', lineHeight: '24px', fontWeight: 700 }}>
              Oferecer uma ampla variedade de produtos e serviços automotivos de qualidade com preço justo, estando próximo ao cliente.
            </p>
          </div>

          <div className="valorContainer">
            <img src={visao} alt="" />
            <h2 className="headingText" style={{ color: '#0B5189' }}>Visão</h2>
            <p style={{ color: '#001926', fontSize: '16px', lineHeight: '24px', fontWeight: 700 }}>
              Ser uma das maiores e mais lucrativas empresas do setor, reconhecida pela inovação e qualidade dos produtos e serviços prestados.
            </p>
          </div>

          <div className="valorContainer">
            <img src={valores} alt="" />
            <h2 className="headingText" style={{ color: '#0B5189' }}>Valores</h2>
            <p id="valoresText" style={{ color: '#001926', fontSize: '16px', lineHeight: '24px', fontWeight: 700 }}>
              • Ética: Sem ética não conseguimos manter parcerias duradouras;
              <br />
              <br />
              • Compromisso com a qualidade e segurança: Trabalhamos com vidas;
              <br />
              <br />
              • Inovação: porque garante vantagens competitivas;
              <br />
              <br />
              • Respeito aos clientes, fornecedores, colaboradores e toda a sociedade: representam os pilares do nosso negócio;
              <br />
              <br />
              • Compromisso com o meio ambiente: somos também responsáveis pelo ambiente que vivemos;
              <br />
              <br />
              • Foco no resultado: É o resultado que garante nosso crescimento;
              <br />
              <br />
              • Objetividade em todas as ações: precisamos ser dinâmicos em nossas atitudes.
            </p>
            <p onClick={openValoresText} style={{ fontSize: '20px', lineHeight: '30px', color: '#0B5189', cursor: 'pointer' }}>
              +
            </p>
          </div>
        </div>

        <div className="pmzNumbersContainer">
          <div className="numberCollum">
            <h2>
              + <span className="numberToSum" id="funcionarios">0</span>
            </h2>
            <p>
              funcionários
            </p>
          </div>

          <div className="numberCollum">
            <h2>
              <span className="numberToSum" id="lojas">0</span>
            </h2>
            <p>
              lojas
            </p>
          </div>

          <div className="numberCollum">
            <h2>
              <span className="numberToSum" id="distribuicao">0</span>
            </h2>
            <p>
              centros de distribuição
            </p>
          </div>

          <div className="numberCollum">
            <h2>
              <span className="numberToSum" id="estados">0</span>
            </h2>
            <p>
              estados do Brasil
            </p>
          </div>

          <div className="numberCollum">
            <h2>
              + <span className="numberToSum" id="itens">0</span> mil
            </h2>
            <p>
              itens no portfólio
            </p>
          </div>

          <div className="numberCollum">
            <h2>
              <span className="numberToSum" id="anos">0</span>
            </h2>
            <p>
              anos de mercado
            </p>
          </div>
        </div>
        
      </div>
      <div id="contato" className="pmzContato">
        <h2 style={{ textAlign: 'center', marginTop: '80px' }} className="headingText">
          Contato
        </h2>
        <div className="contactTextAndForm">
          <div className="contactText">
            <div>
              <h2 style={{ fontSize: '20px', color: '#001926' }}>Estamos à sua disposição!</h2>
              <p style={{ fontSize: '16px', lineHeight: '150%' }}>
                Se não conseguiu sanar a sua dúvida no nosso site, fique à vontade para entrar em contato através do formulário ao lado.
                <br />
                <br />
                <span style={{ color: '#001926', fontWeight: 700 }}>
                  Não oferecemos orçamentos por este canal.
                  Disponibilizamos essa informação nas nossas lojas e pelo telefone ou whatsapp.
                </span>
              </p>
            </div>

            <div id="trabalheConosco" className="hideOnMobile">
              <h2 onClick={() => window.open('https://grupopmz.solides.jobs/')} style={{ fontSize: '24px', color: '#001926', display: 'flex', flexDirection: 'row', gap: '12px', cursor: 'pointer', fontWeight: 900 }}>
                Trabalhe Conosco
                <img src={link} alt="" />
              </h2>

              <p style={{ fontSize: '16px', lineHeight: '150%' }}> 
                Temos muitos desafios para continuarmos crescendo e expandindo em todo o país.
                <br />
                <br />
                <span style={{ cursor: 'pointer', color: '#254E70', fontWeight: 700 }} onClick={() => window.open('https://grupopmz.solides.jobs/')}>
                  Vem construir a sua história com a gente?
                </span>
              </p>
            </div>
          </div>

          <div>
            <div role="main" id="contato-site-tampao-f79aaf1c02be68d5ceb7" />
          </div>

          <div id="trabalheConosco" className="showOnMobile contactText">
            <h2 id="headingFlex" onClick={() => window.open('https://grupopmz.solides.jobs/')} style={{ fontSize: '24px', color: '#001926', display: 'flex', flexDirection: 'row', gap: '12px', cursor: 'pointer', fontWeight: 900 }}>
              Trabalhe Conosco
              <img src={link} alt="" />
            </h2>

            <p style={{ fontSize: '16px', lineHeight: '150%' }}> 
              Temos muitos desafios para continuarmos crescendo e expandindo em todo o país.
              <br />
              <br />
              <span style={{ cursor: 'pointer', color: '#254E70', fontWeight: 700 }} onClick={() => window.open('https://grupopmz.solides.jobs/')}>
                Vem construir a sua história com a gente?
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="findCloseStoreContainer">
        <h2 style={{ textAlign: 'center', marginTop: '80px', color: '#001926', paddingLeft: '28px', paddingRight: '28px' }} className="headingText">
          Encontre a PMZ mais próxima de você
        </h2>
        <div className="findCloseStoreMain">
          <div className="findCloseStoreInput">
            <select onChange={(e) => changeCurrentState(e.target)} className="stateInput findStoresInput" name="State">
              {allStates.map((state) => {
                return (
                  <option>
                    {state.replace('_', ' ')}
                  </option>
                );
              })}
            </select>

            <select value={currentCity.replace('_', ' ')} onChange={(e) => changeCurrentCity(e.target)} className="cityInput findStoresInput" name="State">
              {returnCurrentStateCities().map((city) => {
                return (
                  <option>
                    {city.replace('_', ' ')}
                  </option>
                );
              })}
            </select>

            <p style={{ fontSize: '18px', fontWeight: 700, marginTop: '15px' }}>
              Lojas
            </p>
            <div className="allLojasContainer">
              {mapStores(currentState, currentCity)}
            </div>
          </div>

          <div className="closeStoreData">
            <h2 style={{ fontSize: '24px', fontWeight: 900, color: '#00304B' }}>{cityAndStatePair}</h2>
            <h3 style={{ fontSize: '20px', fontWeight: 700, color: '#001926' }} className="textAndImage"><img alt="" src={Endereco} />Endereço</h3>
            <p>
              {currentStore.Endereço}
            </p>

            <h3 style={{ fontSize: '20px', fontWeight: 700, color: '#001926' }} className="textAndImage"><img alt="" src={Telefone} />Telefone</h3>
            <p>
              {currentStore.telefone === undefined ? ' Não possui telefone' : currentStore.telefone}
            </p>

            <h3 style={{ fontSize: '20px', fontWeight: 700, color: '#001926' }} className="textAndImage"><img alt="" src={Horarios} />Horários de Funcionamento</h3>
            <div className="mondayToSaturdayHours">
              <div className="storeWorkHours">
                <h3 style={{ fontSize: '18px', fontWeight: 700 }}>Segunda à sexta</h3>
                {currentStore.funcionamento.segundaASexta}
              </div>

              <div className="storeWorkHours">
                <h3 style={{ fontSize: '18px', fontWeight: 700 }}>Sábado</h3>
                {currentStore.funcionamento.sabado}
              </div>
            </div>
            <div>
              {
                currentStore.funcionamento.domingo === undefined ? <p style={{ textAlign: 'center' }}>Não abrimos aos domingos</p> 
                  : (
                    <div className="storeWorkHours">
                      <h3 style={{ fontSize: '18px', fontWeight: 700 }}>Domingos</h3>
                      {currentStore.funcionamento.domingo}
                    </div>
  
                  )
              }
              
            </div>
          </div>
        </div>
      </div>

      <div className="pmzFooter">
        <img src={pmzLogo} alt="PMZ Peças e Pneus" />
        <p style={{ fontSize: '13px' }}>A maior rede de peças e pneus do Norte e Centro-Oeste</p>

        <div className="headerLinks">
          <a id="pmzHome" href="#home">
            Home
          </a>
          <a id="pmzSobre" href="#sobreNos">
            Sobre Nós
          </a>
          <a id="pmzConosco" target="_blank" href="https://grupopmz.solides.jobs/">
            Trabalhe Conosco
          </a>
          {/* <a target="_blank" className="escolaMecanicos" href="https://lp.grupopmz.com/pmz-escola-para-mecanicos">
            Escola para Mecânicos
            <img src={externalLink} alt="" />
          </a> */}
          <a id="pmzContato" href="#contato">
            Contato
          </a>
        </div>

        <div className="footerDecoration" />

        <div className="footerSocialsContainer">
          <img onClick={() => window.open('https://www.instagram.com/grupopmz/')} src={instagramWhite} alt="instagram" />
          <img onClick={() => window.open('https://www.linkedin.com/company/pmzpe%C3%A7asepneus/')} src={linkedinWhite} alt="Linkedin" />
          <img onClick={() => window.open('https://www.facebook.com/GrupoPMZ/')} src={facebookWhite} alt="Facebook" />
        </div>
      </div>
      <div className="footerCNPJinfo">
        22.763.502/0017-74 | PMZ DISTRIBUIDORA S.A
      </div>

    </div>
  );
}

export default App;
